import React from 'react';

const synonymsData = {
    troca: ["troca", "troco", "trocar", "trocas"],
    transferencia: ["transferencia", "transfiro", "transferir"],
    consultar: ["consultar", "consulto", "consulta"],
    gerar: ["gerar", "gero", "gera",],
    emissao: ["emitir", "tirar"],
    cadastro: ["cadastro", "cadastrar"],
    entrada: ["entrada", "compra", "compras",],
    estoque: ["estoque"],
    relatorio: ["relatorio", "relatorios", "acompanhamento"],
    contas: ["contas", "conta"],
    vendas: ["vendas", "venda"],
    contato: ["contato", "contatos"],
    anexar: ["anexar", "anexo"],
    produto: ["produto", "produtos"],
    conciliacão: ["conciliacao", "conciliar"],
    consignacão: ["consignacao"],
    devoulucão: ["devolucao", "devolver", "cancelar", "cancelamento"],
    atualizacão: ["atualizacao", "atualizar"],
    lancar: ["lacamento", "lancar", "lanco", "remessa"],
    luxottica: ["luxottica", "luxotica"],
    tecnolens: ["tecnolens"],
    hoya: ["hoya"],
    kenerson: ["kenerson"],
    zeiss: ["zeiss"],
    credito: ["credito"],
    varilux: ["varilux"],
    marchon: ["marchon"],
    mercadoticalab: ["mercadoticalab", "pedido"],
    nota: ["nota", "nota fiscal", "notas fiscais", "nfc", "nfce"],
    notas: ["nota", "nota fiscal", "notas fiscais", "nfc", "nfce"],
    nfc: ["nfc", "nota fiscal", "notas fiscais"],
    nfce: ["nfce", "nfc-e", "nota fiscal", "notas fiscais"],
    movimento: ["movimento", "movimentacao", "movimentar"],
    emitir: ["emitir", "emissao", "emito"],
    cliente: ["cliente", "clientes"],
    remessa: ["remessa",],
    garantia: ["garantia", "garantias"],
    guia: ["guia", "guias"],
    estorno: ["estorno", "estornar", "estornos"],
    baixa: ["baixa", "baixas"],
    desconto: ["desconto", "descontos"],
    etiquetas: ["etiquetas", "etiqueta"],
    cartao: ["cartao", "cartoes"],
    corrigir: ["corrigir", "correcao"],
    parcelar: ["parcelar", "parcela", "parcelamento"],
    recebiveis: ["recebiveis", "debito", "boletos", "boleto"],
    efetivar: ["efetivar"],
    fornecedor: ["fornecedor"],
    efetuado: ["efetuados", "efetuados"],
    documento: ["documento", "documentos"],
    sistema: ["sistema", "versao"],
    mobile: ["mobile"],
    precos: ["precos", "valor", "valores"],
    transportadora: ["transporte", "transportadora"],
    ficha: ["ficha", "fichas"],
    crediario: ["crediario", "crediarios"],
    inativos: ["inativos", "clientes inativos", "inativo"],
    aniversario: ["aniversario"],
    simplificada: ["simplificada", "simplificadas"],
    meta: ["meta", "metas"],
    contagem: ["contagem", "inventario"],
    pagar: ["pagar", "lançar"],
    lançamento: ["lancamento", "lançar", "lançamento", "ordem"],
    serviços: ["servico", "serviço", "serviços"],
    adaptação: ["adaptacao", "adaptaçao"],
    situação: ["situacao", "situaçao"],
    xml: ["xml"],
    importação: ["importacao", "importaçao", "importar"],
    impressão: ["impressao", "imprimir"],
    versão: ["versao", "shop9",],
    acompanhamento: ["acompanhamento", "acompanhar"],
    exportar: ["exportar"],
    cidade: ["cidade"],
    periodo: ["periodo"],
    liberar: ["liberar"],
    sequencia: ["sequencia", "sequencias"],
    lucratividade: ["lucratividade", "lucro"],
    caixa: ["caixa"],
    pagamentos: ["pagamento", "pagamentos"],
    manutenção: ["manutencao", "manutençao"],
    recebimento: ["recebimento", "receber"],
    aberto: ["aberto"],
    carne: ["carne"],
    bancários: ["bancarios"],
    recibo: ["recibo", "recibos"],
    avulso: ["avulso"],
    empresa: ["empresa"],
    ordem: ["ordem"],
    sintegra: ["sintegra"],
    manifesto: ["manifesto"],
    conclusao: ["conclusao", "conclusão"],
    rx: ["rx", "RX"],
    Recife: ["recife"],
    laboratório: ["laboratorio", "laboratório"],
    solicitar: ["solicitar"],
    assistencia: ["assistencia", "assistência"],
    manual: ["manual"],
    pedido: ["pedido"],
    mercadotica: ["mercadotica", "mercadótica", "mercadóticaLAB", "mercadoticalab"],
    industrializacao: ["industrialização", "beneficiamento", 'industrializacao', 'beneficiamento'],
    marcolin: ["marcolin"],

    // Adicione mais conforme necessário
};



export { synonymsData };
