export const FAQList = {
  chatbot: [
    {
      msg: 'Consultar Informações em Cadastro de cliente ou fornecedor',
      src: '1',
    },
    {
      msg: 'Consultar recebíveis em Cadastro de Cliente',
      src: '2',
    },
    {
      msg: 'Consultar Crédito em Cadastro de Cliente',
      src: '3',
    },
    {
      msg: 'Consultar informações em Cadastro de Cliente',
      src: '4',
    },

    {
      msg: 'Consultar e cadastrar transportadora',
      src: '5',
    },
    {
      msg: 'Cadastro de meta de vendas',
      src: '73',
    },
    {
      msg: 'Consultar preços e estoque',
      src: '74',
    },
    {
      msg: 'Troca simplificada',
      src: '75',
    },
    {
      msg: 'Lançar consignação',
      src: '76',
    },
    {
      msg: 'Entrada de produtos por operação',
      src: '77',
    },
    {
      msg: 'Contagem de estoque',
      src: '78',
    },
    {
      msg: 'Gerar contas a pagar',
      src: '79',
    },
    {
      msg: 'Entrada de compras por importação de xml',
      src: '80',
    },
    {
      msg: 'Impressão de etiquetas',
      src: '81',
    },
    {
      msg: 'Atualização de Versão',
      src: '82',
    },
    {
      msg: 'Acompanhamento de estoque',
      src: '83',
    },
    {
      msg: 'Exportar relatório de XML NFC-e',
      src: '84',
    },
    {
      msg: 'Exportar relatório de XML NF-e',
      src: '85',
    },
    {
      msg: 'Tipos de recebimento por Vendedor',
      src: '86',
    },
    {
      msg: 'Corrigir cartões',
      src: '87',
    },
    {
      msg: 'Atualização de situação de ordem de serviço',
      src: '88',
    },
    {
      msg: 'Alteração de informações de recebíveis em aberto',
      src: '89',
    },
    {
      msg: 'Correção de Parcelamento Manual (manifesto)',
      src: '90',
    },

    {
      msg: 'Manifesto de notas fiscais',
      src: '91',
    },
    {
      msg: 'Entrada de produtos sem nota fiscal',
      src: '92',
    },
    {
      msg: 'Conciliação de crédito luxottica',
      src: '93',
    },
    {
      msg: 'Pedido MercadóticaLAB',
      src: '94',
    },
    {
      msg: 'Anexar contato efetuado em cadastro de clientes',
      src: '6',
    },
    {
      msg: 'Anexar documento via sistema',
      src: '7',
    },

    {
      msg: 'Anexar documentos modo Mobile',
      src: '8',
    },
    {
      msg: 'Relatório de ficha de crediário',
      src: '9',
    },
    {
      msg: 'Relatório de pós venda superior a 1 ano(clientes inativos)',
      src: '10',
    },
    {
      msg: 'Relatório de contatos efetuados de Clientes',
      src: '11',
    },
    {
      msg: 'Relatório de Aniversário por período(clientes por período)',
      src: '12',
    }, {
      msg: 'Relatório de consignação',
      src: '13',
    },
    {
      msg: 'Relatório de metas de vendas',
      src: '14',
    },
    {
      msg: 'Relatório de transferência',
      src: '15',
    },
    {
      msg: 'Relatório de ordem de serviços por situação',
      src: '16',
    },
    {
      msg: 'Relatório de vendas por cidade e período',
      src: '17',
    },
    {
      msg: 'Relatório de inventário',
      src: '18',
    },
    {
      msg: 'Relatório de recebíveis em aberto',
      src: '19',
    },
    {
      msg: 'Relatório de lançamentos bancários',
      src: '20',
    },
    {
      msg: 'Relatório de  crédito da empresa',
      src: '21',
    },
    {
      msg: 'Relatório de crédito do cliente',
      src: '22',
    },
    {
      msg: 'Relatório de ordem de serviços por prazo de conclusão',
      src: '23',
    },

    {
      msg: 'Relatório de lucratividade por produto',
      src: '24',
    },
    {
      msg: 'Relatório de NFC-e',
      src: '25',
    },
    {
      msg: 'Relatório de movimentação de produtos',
      src: '26',
    },
    {
      msg: 'Relatório de produtos-vendas ABC',
      src: '27',
    },
    {
      msg: 'Relatório de lucratividade agrupada',
      src: '28',
    },

    {
      msg: 'Relatório de NF-e',
      src: '29',
    },
    {
      msg: 'Relatório do caixa',
      src: '30',
    },

    {
      msg: 'Relatório de vendas com desconto',
      src: '31',
    },
    {
      msg: 'Relatório de contas a pagar',
      src: '32',
    },
    {
      msg: 'Relatório de contas pagas',
      src: '33',
    },
    {
      msg: 'Remessa em garantia tecnolens',
      src: '34',
    },
    {
      msg: 'Remessa em garantia interestadual(GO-Kenerson)',
      src: '35',
    },
    {
      msg: 'Remessa em garantia laboratório RX- Recife',
      src: '36',
    },

    {
      msg: 'Remessa em garantia Hoya-/BA/PE/MT/SE',
      src: '37',
    },
    {
      msg: ' solicitar garantias kenerson',
      src: '95',
    },
    {
      msg: 'Guia de Adaptação Varilux',
      src: '38',
    },
    {
      msg: 'Guia de Assistencia Técnica Marchon',
      src: '39',
    },
    {
      msg: 'Manual de Garantia Zeiss saoWeb',
      src: '40',
    },

    {
      msg: 'Liberar movimento em sequência',
      src: '41',
    },
    {
      msg: 'Movimentação do caixa',
      src: '42',
    },
    {
      msg: 'Gerar movimento de SINTEGRA',
      src: '43',
    },

    {
      msg: 'Devolução de produtos para tecnolens',
      src: '44',
    },
    {
      msg: 'Devolução de troca antecipada-Luxottica',
      src: '45',
    },
    {
      msg: 'Devolução de consignação',
      src: '46',
    },
    {
      msg: 'Devolução de vendas',
      src: '47',
    },
    {
      msg: 'Devolução de produto em crédito-Luxottica',
      src: '48',
    },

    {
      msg: 'Emitir  NFC-e',
      src: '49',
    },
    {
      msg: 'Emitir NF-e',
      src: '50',
    },
    {
      msg: 'Emitir de boletos',
      src: '51',
    },
    {
      msg: 'Emitir de carnê com código de barras',
      src: '52',
    },
    {
      msg: 'Emitir de recibo avulso para empresa ou cliente',
      src: '53',
    },

    {
      msg: 'Estorno de crédito da empresa',
      src: '54',
    },
    {
      msg: 'Estorno de crédito do cliente',
      src: '55',
    },
    {
      msg: 'Transferência entre filiais',
      src: '56',
    },
    {
      msg: 'Transferência entre contas bancárias',
      src: '57',
    },
    {
      msg: 'Transferência entre uma conta para um caixa',
      src: '58',
    },
    {
      msg: 'Transferência de crédito da empresa',
      src: '59',
    },

    {
      msg: 'Transferência de crédito de cliente',
      src: '60',
    },

    {
      msg: 'Lançamento de OS',
      src: '61',
    },
    {
      msg: 'Lançamentos bancários',
      src: '62',
    },
    {
      msg: 'Lançamento de crédito a empresa',
      src: '63',
    },

    {
      msg: 'Baixa de pagamentos-Manutenção de contas a pagar',
      src: '64',
    },
    {
      msg: 'Baixa de recebimentos-Manutenção de contas a receber',
      src: '65',
    },
    {
      msg: 'Venda estadual',
      src: '66',
    },
    {
      msg: 'Lançar vendas diversas',
      src: '67',
    },
    {
      msg: 'Venda entre franquias',
      src: '68',
    },
    {
      msg: 'Descontos em vendas',
      src: '69',
    },
    {
      msg: 'Efetivar vendas',
      src: '70',
    },
    {
      msg: 'pós venda de adaptação',
      src: '71',
    },
    {
      msg: 'pós venda de aniversário',
      src: '72',
    },
    {
      msg: 'Remessa em industrialização tecnolens',
      src: '96',
    },
    {
      msg: 'Manual de garantia Marcolin',
      src: '97',
    },

  ],

  Nao: [
    {
      msg: 'Falar com suporte',
      src: '1',
    },
  ],

  Devolução: [
    {
      msg: 'Devolução de produtos para tecnolens',
      src: '44',
    },
    {
      msg: 'Devolução de troca antecipada',
      src: '45',
    },
    {
      msg: 'Devolução de consignação',
      src: '46',
    },
    {
      msg: 'Devolução de vendas',
      src: '47',
    },
    {
      msg: 'Devolução de produto em crédito',
      src: '48',
    },

  ],

  Vendas: [
    {
      msg: 'Venda estadual',
      src: '66',
    },
    {
      msg: 'Lançar vendas diversas',
      src: '67',
    },
    {
      msg: 'Venda entre franquias',
      src: '68',
    },
    {
      msg: 'Descontos em vendas',
      src: '69',
    },
    {
      msg: 'Efetivar vendas',
      src: '70',
    },
  ],

  Cadastro: [
    {
      msg: 'Consultar Informações em Cadastro de cliente',
      src: '1',
    },
    {
      msg: 'Consultar recebíveis em Cadastro de Cliente',
      src: '2',
    },
    {
      msg: 'Consultar Crédito em Cadastro de Cliente',
      src: '3',
    },
    {
      msg: 'Consultar informações em Cadastro de Cliente',
      src: '4',
    },

    {
      msg: 'Consultar e cadastrar transportadora',
      src: '5',
    },
    {
      msg: 'Cadastro de meta de vendas',
      src: '73',
    },
  ],


  Movimento: [
    {
      msg: 'Liberar movimento em sequência',
      src: '41',
    },
    {
      msg: 'Movimentação do caixa',
      src: '42',
    },
    {
      msg: 'Gerar movimento de SINTEGRA',
      src: '43',
    },
  ],


  Guias: [
    {
      msg: 'Guia de Adaptação Varilux',
      src: '38',
    },
    {
      msg: 'Guia de Assistencia Técnica Marchon',
      src: '39',
    },
  ],

};
