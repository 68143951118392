import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { RootState } from '../../redux/stores';
import socket from '../../services/socket';

interface PrivateRouteProps {
  children: ReactNode;
}

export function PrivateRoute({ children }: PrivateRouteProps) {
  const { user } = useSelector((state: RootState) => state.userReducer);
  const [socketConnected, setSocketConnected] = useState(false);

  useEffect(() => {
    const connectSocket = () => {
      console.log(`Conectando ao socket ${user.usuario}`);
      socket.auth = { username: user.usuario };

      socket.connect();

      socket.on('connect', () => {
        console.log('Socket conectado com sucesso!');
        setSocketConnected(true);
      });

      socket.on('connect_error', (error) => {
        console.error('Erro de conexão:', error);

      });

      socket.on('reconnect_failed', () => {
        console.error('Falha ao reconectar ao servidor  após várias tentativas.');

      });
    };

    connectSocket(); // Conectar o socket imediatamente ao montar o componente

    return () => {
      if (!user) {
        socket.disconnect();
        setSocketConnected(false);
      }
    };
  }, [user]); // Executar o useEffect novamente quando o usuário mudar

  useEffect(() => {
    if (user) {
      // Aqui você pode fazer qualquer outra inicialização relacionada ao usuário, se necessário
    }
  }, [user]);

  return <div>{user.nome ? (user.admin ? <Navigate to="/admin" /> : children) : <Navigate to="/login" />}</div>;
}
