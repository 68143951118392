
{/* 
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { notificationAction, roomsAction } from '../../redux/actions/index.action';
import { RootState } from '../../redux/stores';
import socket from '../../services/socket';
import { notification } from '../../utils/notification';

interface PrivateRouteAdminProps {
  children: ReactNode;
}

export function PrivateRouteAdmin({ children }: PrivateRouteAdminProps) {
  const { user } = useSelector((state: RootState) => state.userReducer);
  const dispatch = useDispatch();
  const [socketConnected, setSocketConnected] = useState(false);

  // Função para salvar o usuário no localStorage
  const saveUserToLocalStorage = (userData: any) => {
    localStorage.setItem('user', JSON.stringify(userData));
  };

  // Função para recuperar o usuário do localStorage
  const getUserFromLocalStorage = () => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  };

  useEffect(() => {
    const storedUser = getUserFromLocalStorage();

    if (storedUser && !user) {
      // Se houver um usuário no localStorage e não estiver no estado do Redux
      // Poderia despachar uma ação para salvar esse usuário no estado global (Redux)
      // dispatch(loginAction(storedUser)); // Exemplo: despachando uma ação de login
    }

    const connectSocket = () => {
      if (!storedUser) return; // Verifica se o usuário está disponível

      console.log(`Conectando ao socket ${storedUser.usuario}`);
      socket.auth = { username: storedUser.usuario };

      socket.connect();

      socket.on('connect', () => {
        console.log('Socket conectado com sucesso!');
        setSocketConnected(true);
      });

      socket.on('connect_error', (error) => {
        console.error('Erro de conexão:', error);
      });

      socket.on('reconnect_failed', () => {
        console.error('Falha ao reconectar ao servidor WebSocket após várias tentativas.');
      });
    };

    connectSocket();

    return () => {
      if (!storedUser) {
        socket.disconnect();
        setSocketConnected(false);
      }
    };
  }, [user]);

  socket.on('rooms', (data) => {
    const room = data.map((room: string) => {
      return {
        name: room,
        unreadCount: 0,
      };
    });
    dispatch(roomsAction(room));
    dispatch(notificationAction(room.length));
  });

  const storedUser = getUserFromLocalStorage();

  return (
    <div>
      {storedUser ? storedUser.admin ? children : <Navigate to="/home" replace /> : <Navigate to="/login" replace />}
    </div>
  );
}

*/}






import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { notificationAction, roomsAction } from '../../redux/actions/index.action';
import { RootState } from '../../redux/stores';
import socket from '../../services/socket';
import { notification } from '../../utils/notification';

interface PrivateRouteAdminProps {
  children: ReactNode;
}

export function PrivateRouteAdmin({ children }: PrivateRouteAdminProps) {
  const { user } = useSelector((state: RootState) => state.userReducer);
  const dispatch = useDispatch();
  const [socketConnected, setSocketConnected] = useState(false);

  useEffect(() => {
    const connectSocket = () => {
      console.log(`Conectando ao socket ${user.usuario}`);
      socket.auth = { username: user.usuario };


      socket.connect();

      socket.on('connect', () => {
        console.log('Socket conectado com sucesso!');

        setSocketConnected(true);
      });

      socket.on('connect_error', (error) => {
        console.error('Erro de conexão:', error);

      });

      socket.on('reconnect_failed', () => {
        console.error('Falha ao reconectar ao servidor WebSocket após várias tentativas.');

      });
    };

    connectSocket(); // Conectar o socket imediatamente ao montar o componente

    return () => {
      if (!user) {
        socket.disconnect();
        setSocketConnected(false);
      }
    };
  }, [user]); // Executar o useEffect novamente quando o usuário mudar


  socket.on('rooms', (data) => {
    console.log(data);
    const room = data.map((room: string) => {
      return {
        name: room,
        unreadCount: 0,
      };
    });
    dispatch(roomsAction(room));
    dispatch(notificationAction(room.length));
    // notification();
  });

  return (
    <div>{user.nome ? user.admin ? children : <Navigate to="/home" replace /> : <Navigate to="/login" replace />}</div>
  );
}
